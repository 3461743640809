// src/components/SSORedirect.js
//TOKEN EXAMPLE 
// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJvYmVzaWRhZGFsYWNhcnRhLmNvbSIsImlhdCI6MTcyMTcyNjIyNSwiZXhwIjoxNzUzMjYyMjI1LCJhdWQiOiJvYmVzaWRhZGFsYWNhcnRhLmNvbSIsInN1YiI6InNlcmdpby5mb3JjZW5AZGF0YWJvdC5lcyIsImVtYWlsIjoic2VyZ2lvLmZvcmNlbkBkYXRhYm90LmVzIiwiaWQiOiIxIiwiYXZhdGFyIjoidXJsOlwiL3VwbG9hZHMvZW1lcmFsZC91c2VyL2F2YXRhci8xL25ld3Bsb3RfMTFfLnBuZ1wiIiwibmFtZSI6IlNlcmdpbyIsInN1cm5hbWUiOiJGb3JjZW4iLCJjb3VudHJ5IjoiU3BhaW4iLCJjaXR5IjoiQmFyY2Vsb25hIiwicHJvZmVzc2lvbiI6IkRldmVsb3BlciIsImV4cGVyaWVuY2UiOiIxMDMyIiwibGV2ZWwiOiIzIiwicGhvbmUiOiIzMzQ1NDEyMyIsInN0dWR5X3R5cGUiOiIxMjAiLCJiaW9ncmFwaHkiOiJFeGFtcGxlYXNkYXNkYXNkIn0.7DYMUSMDaxz8eQCy_bbPnk2eTJZEgvMhC9XucndyS1I
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { SSO_COOKIE_URL } from "../constants/api-urls";

const SSORedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Esta lógica se ejecutará en cada renderizado
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  if (token) {
    try {
      const decoded = jwtDecode(token);
      // Verificar si el token está expirado
      // const currentTime = Date.now() / 1000; // convertir a segundos
      // if (decoded.exp < currentTime) {
      //   throw new Error('Token expirado');
      // }

      // Guardar datos del usuario en localStorage
      window.localStorage.clear();
      fetch(SSO_COOKIE_URL, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` // Coloca el token en el encabezado Authorization
        },
      }).catch((err) => {
        console.log("Connection error: ", err);
      });
      window.localStorage.setItem('user', JSON.stringify(decoded));
      window.localStorage.setItem("user_caducity", Date.now());
      window.location.href = decoded.target || "/";

      // Redireccionar
    } catch (e) {
      console.error('Invalid token', e);
      alert(`Ha habido un error con el token: ${e.message}`);
      navigate('/');
    }
  } else {
    navigate('/');
  }

  return <div>Redireccionando...</div>;
};

export default SSORedirect;