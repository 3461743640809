import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoutes = ({ user, timestamp, children }) => {
  // Si el usuario no está logueado, redirigir a "https://www.onenovonordisk.com/one-novonordisk.html"
  if (!user) {
    //return <Navigate to="https://www.onenovonordisk.com/one-novonordisk.html" replace={true} state={{ from: window.location.pathname }} />;
    window.location.href = "https://www.onenovonordisk.com/one-novonordisk.html";
    return null;
  }

  return children ? children : <Outlet/>
};