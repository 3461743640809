import * as React from "react";
import "../stylesheets/account.scss";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { LOGOUT_URL, getAvatarUrl } from "../constants/api-urls";

export default function AccountMenu() {
  const user = JSON.parse(localStorage.getItem('user'));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = () => {
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('user_caducity');
    fetch(LOGOUT_URL, {
            method: "POST",
            credentials: 'include',
        });
  }
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar src={getAvatarUrl(user)} sx={{ width: 45, height: 45 }}></Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <div className="menu-item px-3">
            <div className="menu-content-profile px-3">
              <div className="symbol me-5">
                <Avatar src={getAvatarUrl(user)} />
              </div>
              <div className="d-flex flex-column">
                <div className="name-profile">
                  {user.name} {user.surname}
                  <span className="badge-lebel-profile">Nivel {user.level}</span>
                </div>
                <span className="mail-profile">{user.email}</span>
              </div>
            </div>
          </div>
        </MenuItem>

        <Divider />
        <MenuItem
          className="menu-item dropdown-item px-5"
          onClick={handleClose}
        >
          <a className="menu-link px-5 w-100" href="/perfil">
            Mi perfil
          </a>
        </MenuItem>
        <MenuItem
          className="menu-item dropdown-item px-5"
          onClick={handleClose}
        >
          <a
            className="menu-link px-5 w-100"
            href="/"
            onClick={handleLogOut}
          >
            Cerrar sesión
          </a>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
