import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ProtectedRoutes } from "./components/ProtectedRoutes";
import { Home } from "./pages/Home";
import { Agenda } from "./components/Agenda";
import { Contact } from "./pages/Contact";
import { AgendaEventos } from "./pages/AgendaEventos";
import { ContactMedic } from "./pages/ContactMedic";
import { Logros } from "./pages/Logros";
import { Restaurant } from "./pages/Restaurant";
import { Profile } from "./pages/Profile";
import { SignIn } from "./pages/SignIn";
import { PasswordReset } from "./pages/PasswordReset";
import { Plato } from "./pages/Plato";
import { ProfileEdit } from "./pages/ProfileEdit";
import { RecomendationsForm } from "./pages/RecomendationsForm";
import SSORedirect from "./pages/SSORedirect";
import { useLocation } from "react-router-dom";

const LocationDisplay = ({ setCurrentPath }) => {
  const location = useLocation();
  React.useEffect(() => {
    if (setCurrentPath) {
      setCurrentPath(location.pathname);
    }
  }, [location, setCurrentPath]);

  return null;
};

export const AppRouter = ({ setCurrentPath }) => {
  // Por ahora comprobar simplemente user,
  // en el futuro se podría comprobar la caducidad del token
  const user = window.localStorage.getItem("user");
  const timestamp = window.localStorage.getItem("user_caducity");
  return (
    <Router>
      <LocationDisplay setCurrentPath={setCurrentPath} />
      <Routes>
        <Route
          path="admin-login"
          element={<SignIn pageTitle="Iniciar Sesión - MOC" />}
        />
        <Route
          path="password-reset"
          element={<PasswordReset pageTitle="Contraseña olvidada - MOC" />}
        />
        <Route path="sso" element={<SSORedirect />} />
        <Route element={<ProtectedRoutes user={user} timestamp={timestamp} />}>
          <Route path="/" element={<Home pageTitle="Home - MOC" />} />
          <Route path="/#agenda" element={<Home pageTitle="Home - MOC" />} />
          <Route
            path="agendaeventos"
            element={<AgendaEventos pageTitle="Agenda de eventos - MOC" />}
          />
          <Route
            path="contact"
            element={<Contact pageTitle="Contacto - MOC" />}
          />
          <Route
            path="area-medica"
            element={<ContactMedic pageTitle="Contacto Médico - MOC" />}
          />
          <Route path="restaurante/:id" element={<Restaurant />} />
          <Route path="logros" element={<Logros />} />
          <Route path="restaurante/:category_id/plato/:id" element={<Plato />} />
          <Route path="perfil" element={<Profile pageTitle="Perfil - MOC" />} />
          <Route path="recomendaciones" element={<RecomendationsForm />} />
          <Route
            path="perfil/edit"
            element={<ProfileEdit pageTitle="Editar Perfil - MOC" />}
          />
          <Route
            path="agenda"
            element={<Agenda pageTitle="Iniciar Sesión - MOC" />}
          />
        </Route>
      </Routes>
    </Router>
  );
};
