import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../stylesheets/variables.scss";
import "../stylesheets/navbar.scss";
import logo from "../images/logo_navbar.png";
import AccountMenu from "./AccountMenu";
import { BASE_URL, BACKEND_URL } from "../constants/api-urls.js";
import { LOGOUT_URL } from "../constants/api-urls";
import Spinner from "../components/Spinner";

function OffcanvasExample({ selectedCategory }) {
  const [categories, setCategories] = useState([]);

  const getLogoUrl = (id) => {
    try {
      return require(`../images/home/restaurante/logo/img-restaurante-logo-${id}.png`);
    } catch (error) {
      console.error("Error loading image:", error);
      return '';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}digital_entities/getCategories`,
          {
            credentials: "include", // Include credentials
          }
        );
        if (!response.ok) {
          if (response.status === 401) {
            window.localStorage.removeItem("user");
            window.localStorage.removeItem("user_caducity");
            fetch(LOGOUT_URL, {
              method: "POST",
              credentials: "include",
            });
            window.location.replace("/");
          }
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // const handleCategorySelection = (category) => {
  //   setSelectedCategory(category);
  // };

  return (
    <>
      {["md"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="bg-body bg-white mb-3 h-100 container"
        >
          <Container fluid>
            <Navbar.Brand href="/">
              <img src={logo} alt="Logo" className="logo_navbar"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-start flex-grow-1 pe-3">
                  <NavDropdown
                    title="Restaurantes"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    {categories.length === 0 && <Spinner />}
                    {categories.map((category) => (
                      <NavDropdown.Item
                        key={category.id}
                        href={`/restaurante/${category.id}`}
                      >
                        <img
                          height="30px"
                          src={getLogoUrl(category.id)}
                          alt="Logo "
                        />
                        {category.title}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                  <Nav.Link href="/agendaeventos">Agenda de eventos</Nav.Link>
                  <Nav.Link href="/contact">Contacto</Nav.Link>
                </Nav>
                <AccountMenu />
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default OffcanvasExample;
